<template>
  <div class="wrap">
    <div class="share_content">
      <i class="iconfont icon-wodekefu btn_server_icon" @click="toService"></i>
    </div>
    <div class="titles">
      <!-- <span class="welcome">{{ $t('login.welcome') }}</span> -->
      <div class="logo">
        <VImg height="70" :src="require('@/assets/logo/login.png')" />
      </div>
      <div class="circle"></div>
      <div class="circle1"></div>
      <div class="circle2"></div>
    </div>
    <div class="form_wrap_bar">
      <div class="tabs">
        <div class="tab_item" :class="index == tabActive ? 'tab_active' : ''" v-for="item, index in tabs" :key="index"
          @click="tabClick(index)">{{ $t(item) }}</div>
      </div>
      <template v-if="tabActive == 0">
        <LoginForm :formList="formList" :isCancel="false" confirmText="Login.loginButton"
          readText="login.rememberPassword" :getRemember="true" @submit="onSubmit">
        </LoginForm>
      </template>
      <template v-else>
        <RegistrasiForm :formList="registrasiformList" :isCancel="false" confirmText="Login.Forgot.confirm"
          readText="hint.registeRead" :needRead="true" @submit="onRegistSubmit">
        </RegistrasiForm>
      </template>
    </div>
  </div>
</template>

<script>
import { Icon, Image as VImg, Toast, Tabs } from "vant";
import LoginForm from "./components/form.vue";
import RegistrasiForm from "./components/form.vue";
import { SEND_SMS_CODE, LOGIN } from "@/api";
import { getNewPhone } from "@/utils/tools";
import { $post } from "../../utils/request";
export default {
  components: {
    Icon,
    VImg,
    LoginForm,
    RegistrasiForm
  },
  data() {
    return {
      formList: [
        {
          key: "userphone",
          flag: true,
          icon: "icon-yonghuming",
          label: "Login.nameInput",
          rules: [{ required: true, message: this.$t("hint.usernameEnter") }],
        },
        {
          label: "Login.pwdInput",
          type: "password",
          key: "userpass",
          icon: "icon-zhanghumima",
          flag: true,
          rules: [{ required: true, message: this.$t("hint.passwordEnter") }],
          isPassword: true,
        },
      ],
      registrasiformList: [{
        key: "userphone",
        flag: true,
        icon: "icon-yonghuming",
        // label: "Login.Daftar.userName",
        label: "Login.nameInput",
        rules: [{ required: true, message: this.$t("hint.usernameEnter") }],
      }, {
        label: "Login.Daftar.password",
        type: "password",
        key: "userpass",
        icon: "icon-zhanghumima",
        flag: true,
        rules: [{ required: true, message: this.$t("hint.passwordEnter") }],
        isPassword: true,
      }, {
        label: "Login.Daftar.againPassword",
        type: "password",
        key: "againPassword",
        icon: "icon-zhanghumima",
        flag: false,
        rules: [
          { required: true, message: this.$t("hint.passwordAgainEnter") },
        ],
        isPassword: true,
      }, {
        label: "Login.Daftar.referrer",
        type: "text",
        icon: "icon-yaoqing2",
        key: "fromid",
        flag: true,
        rules: [{ required: true, message: this.$t("hint.formidEnter") }],
      }],
      tabs: ['newAdd.Login', 'Login.registrasi'],
      tabActive: 0
    };
  },
  methods: {
    toService() {
      this.$api.toService();
    },
    tabClick(index) {
      this.tabActive = index
    },
    onSubmit(values, read) {
      Toast.loading({ duration: 0 });
      LOGIN(values) // http请求
        .then((r) => {
          if (r.data.ret === 1) {
            this.$cookie.set("userInfo", JSON.stringify(r.data.data));
            this.$cookie.set("token", r.data.data.token);
            this.$cookie.set("the-pwd-state", this.checked);
            if (read) {
              this.$cookie.set(
                "the-pwd-obj",
                JSON.stringify({
                  userphone: values.userphone,
                  userpass: values.userpass,
                })
              );
            } else {
              this.$cookie.remove("the-pwd-obj");
            }
          } else {
            Toast.clear();
            Toast(r.data.msg);
          }
          return r.data.ret;
        })
        .then(async (ret) => {
          if (ret == 1) {
            this.$store.dispatch("getSystemConfig");
            try {
              const res = await $post("/users/info");
              const { ret, msg, data } = res.data;
              if (ret == 1) {
                // this.LoadingShow = false;
                Toast.clear();
                this.$router.replace({ name: "goods" });
                this.$cookie.set("userInfo", JSON.stringify(data));
              }
            } catch (error) {
              console.error("登录错误", error);
              Toast.clear();
            }
          }
        })
        .catch((e) => {
          Toast.clear();
          console.log(e);
        });
    },
    onRegistSubmit(values) {
      Toast.loading({ duration: 0 });
      const { againPassword, ...useValues } = values;
      if (againPassword === useValues.userpass) {
        if (!this.formList.find((item) => item.key === "code")) {
          useValues.code = "112233";
        }
        LOGIN(useValues)
          .then((r) => {
            if (r.data.ret === 1) {
              this.$cookie.set("userInfo", JSON.stringify(r.data.data));
              this.$cookie.set("token", r.data.data.token);
              if (this.$cookie.get("fromid")) {
                this.$cookie.remove("fromid");
              }
              this.$store.dispatch("getSystemConfig");
              this.$router.replace("goods");
            } else {
              Toast.clear();
              Toast(r.data.msg);
            }
          })
          .catch((e) => {
            Toast.clear();
            console.log(e);
          });
        return;
      }
      Toast(this.$t("Hint.pwdInconformity"));
    },
    toRegiste() {
      this.$router.push("/registrasi");
    },
  },
};
</script>

<style lang="less" scoped>
@color: #E6ab72;

.wrap {
  background: #596A74;
  min-height: 100vh;
  overflow: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @wrapPadding: calc(12rem / 16);

  .share_content {
    // padding-right: 40px;
    // color: #8ba6c1;
    position: absolute;
    right: @wrapPadding;
    top: 20px;
    line-height: calc(60rem / 16);
    color: @color;
    z-index: 99;

    .btn_server_icon {
      font-size: calc(34rem / 16);
    }

    img {
      width: 7vw;
      height: 7vw;
    }
  }

  .deline {
    padding: 0 calc(17rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(30rem / 16);
    color: var(--light);

    .left {
      flex: 1;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    .text {
      margin: 0 calc(10rem / 16);
    }

    .right {
      .left();
      background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }
  }

  .partners {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: calc(15rem / 16) calc(17rem / 16) calc(15rem / 16);

    >.partner_img {
      // height: 60px;
      width: 100%;
    }
  }

  .titles {
    height: calc(150rem / 16);
    color: @color;
    line-height: calc(150rem / 16);
    font-size: calc(22rem / 16);
    // padding-left: calc(30rem / 16);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .welcome{
      position: absolute;
      left: calc(30rem / 16);
    }
    .logo {
      opacity: .9;
      // margin-left: calc(20rem / 16);
      display: flex;
      align-items: center;
    }

    .comon {
      position: absolute;
      width: calc(60rem / 16);
      height: calc(60rem / 16);
      border-radius: 50%;
      background-color: @color;
      opacity: .3;

    }

    .circle {
      .comon();
      left: calc(-35rem / 16);
      top: 50%;
    }

    .circle1 {
      .comon();
      @width: calc(80rem / 16);
      width: @width;
      height: @width;
      right: calc(20rem / 16);
      bottom: calc(@width / -2)
    }

    .circle2 {
      .comon();
      @width: calc(150rem / 16);
      width: @width;
      height: @width;
      right: calc(-100rem / 16);
      z-index: 1;
      bottom: calc(-100rem / 16);
    }
  }

  .form_wrap_bar {
    background-color: #fff;
    padding: calc(1rem / 16) 0 calc(30rem / 16);
    border-radius: calc(20rem / 16) calc(40rem / 16) 0 0;
    min-height: calc(100vh - (150rem / 16) - (31rem / 16));

    .tabs {
      display: flex;
      padding: calc(10rem / 16) calc(30rem / 16);
      column-gap: calc(40rem / 16);

      .tab_item {
        color: #333;
        padding-bottom: calc(5rem / 16);
        border-bottom: 3px solid transparent;
      }

      .tab_active {
        color: @color;
        border-bottom: 3px solid @color;
      }
    }
  }

  .nav {
    // height: calc(50rem / 16);
    color: var(--textColor);
    display: flex;
    align-items: center;
    margin: calc(26rem / 16) calc(17rem / 16) 0;

    .back {
      transform: rotate(90deg);
    }

    .title {
      font-size: calc(24rem / 16);
      margin-left: calc(14rem / 16);
    }
  }
}
</style>